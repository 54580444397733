export interface ButtonValues {
    seriesAddButton: boolean;
    seriesAddButtonText?: string;
    seriesMenuButton: boolean;
    seriesMenuButtonText: string;
    buttonColumnWidth: number;
    seriesLeftRightButton: boolean;
}
export const defaultButtonValues: ButtonValues = {
    seriesAddButton: false,
    seriesAddButtonText: "Book It",
    seriesMenuButton: false,
    seriesMenuButtonText: "",
    buttonColumnWidth: 89,
    seriesLeftRightButton: true
}
