export interface RowValues {
    hightlightRow?: boolean;
    hightlightRowColor?: string;
    usePointer?: boolean;
    disableRowTrigger?: boolean;
    isTriggerable?: boolean;
    includeRowNumbers?: boolean;
    mouseClickTriggers?: boolean;
}
export const defaultRowValues: RowValues = {
    hightlightRow: false,
    hightlightRowColor: "lightsteelblue",
    usePointer: false,
    disableRowTrigger: false,
    isTriggerable: false,
    includeRowNumbers: true,
    mouseClickTriggers: false
};
