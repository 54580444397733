import { is_number } from "./UtilityFunctions";

export function format_weight(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : (Number(value) * 0.002205)
        if (measurement) {
            if (calc_value > 1000) {
                calc_value = calc_value / 1000
                return "kg"
            }
            return "gm"
        } else {
            if (calc_value > 2000) {
                calc_value = calc_value / 2000
                return "t"
            }
            return "lb"
        }
    }
    return ""
}

export function format_length(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : (Number(value) * 0.002205)
        if (measurement) {
            if (calc_value > 1000) {
                calc_value = calc_value / 1000
                return "m"
            }
            return "mm"
        } else {
            if (calc_value > 12) {
                calc_value = calc_value / 12
                return "ft"
            }
            return "in"
        }
    }
    return ""
}
export function format_speed(value: string, measurement: boolean) {
    if (is_number(value)) {
        if (measurement) {
            return "km/h"
        } else {
            return "mph"
        }
    }
    return ""
}
export function format_temperature(value: string, measurement: boolean) {
    if (is_number(value)) {
        if (measurement) {
            return "C"
        } else {
            return "F"
        }
    }
    return ""
}
