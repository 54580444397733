import { CotaOptions } from "types";
import { check_map_for_flagged } from "./UtilityFunctions";

export function get_column_width(index: number, options: CotaOptions) {
    let column_width = undefined
    if (options.columnOptions?.columnMinWidth !== undefined) {
        if (options.columnOptions?.columnMinWidth > 0) {
            column_width = options.columnOptions?.columnMinWidth.toString()
        }
    }
    if (options.columnOptions?.columnsEachWidth !== undefined) {
        if (options.columnOptions?.columnsEachWidth !== "") {
            if (options.columnOptions?.columnMinWidth > 0) {
                column_width = options.columnOptions?.columnMinWidth.toString()
            }
            let values = options.columnOptions?.columnsEachWidth.split(/[, ]+/)
            column_width = check_map_for_flagged(values, index, column_width)
        }
    }
    if (column_width !== undefined && column_width !== "") {
        const lastChar = column_width.at(-1);
        if (lastChar !== "%") {
            return column_width+"px"
        }
    }
    return "inherit"
}
export function get_column_color(index: number, options: CotaOptions, override_color?: string | undefined) {
    let column_color = (override_color !== undefined) ? override_color : "#000"
    if (options.colorOptions?.defaultColumnColor !== undefined && options.colorOptions?.defaultColumnColor !== "") {
        column_color = options.colorOptions?.defaultColumnColor
    }
    if (options.colorOptions?.columnsEachColor !== undefined) {
        if (options.colorOptions?.columnsEachColor !== "") {
            let values = options.colorOptions?.columnsEachColor.split(/[, ]+/)
            column_color = check_map_for_flagged(values, index, column_color)
        }
    }
    return column_color
}
export function get_header_css(options: CotaOptions) {
    let header_css = "cota_header_table cota_table_width_adjustment"
    if (!options.disableSearch) {
        header_css = header_css+" cota_header_table_search_height"
    }
    return header_css
}
export function get_row_border(options: CotaOptions) {
    if (options.toggleRowBorder) {
        return " cota_table_row_border"
    }
    return ""
}
export function get_cell_border(options: CotaOptions) {
    if (options.toggleColumnBorder) {
        return " cota_table_column_bottom"
    }
    return ""
}
