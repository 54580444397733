import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { DataFrame, Field, FieldType, PanelData, PanelProps, toDataFrame, VariableModel } from '@grafana/data';
import { CotaOptions } from 'types';
import { css, cx } from 'emotion';
import { stylesFactory, useTheme,  } from '@grafana/ui';
import { Button, Form } from 'react-bootstrap';
import { check_if_flagged, strip_text, check_map_for_flagged, check_value_existance, truncate_string, is_number } from './partials/ViewController/UtilityFunctions';
import { get_column_width, get_column_color, get_header_css, get_row_border, get_cell_border } from "./partials/ViewController/ViewFunctions"
import { convert_to_bold, convert_to_italic, convert_to_currency, convert_to_phone, convert_to_decimal, convert_to_percent, convert_weight, convert_length, convert_speed, convert_temperature} from 'partials/ViewController/ConvertFunctions';
import { format_weight, format_length, format_speed, format_temperature } from "./partials/ViewController/FormatFunctions"
import { RiWallet3Line } from "react-icons/ri";
import { MdLocationPin } from "react-icons/md";
import { GiTruck } from "react-icons/gi";
// import { BsFillPersonFill } from "react-icons/bs";
// import { HiDotsVertical } from "react-icons/hi";

import { getTemplateSrv, locationService } from '@grafana/runtime';



declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
// import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import "./styles/cota_panel.css"
// import "./styles/cota_iframes.css"

interface Props extends PanelProps<CotaOptions> {
}

if (window.top !== window.self) {
    require('./styles/cota_iframe.css');
}
type JsonPrimitive = string | number | boolean | null
interface JsonMap extends Record<string, JsonPrimitive | JsonArray | JsonMap> {}
interface JsonArray extends Array<JsonPrimitive | JsonArray | JsonMap> {}
// Extend VariableModel  to avoid ts errors
interface ExtendedVariableModel extends VariableModel {
    current: {
        selected: boolean,
        value: string;
        text: string;
    }
}
let events_attached = false
// { options, data, timeZone, timeRange, width, height, replaceVariables }
export const SimpleCotaPanel: React.FC<Props> = ({ options, data, timeZone, timeRange, width, height, replaceVariables}) => {
    // const { sync, canAddAnnotations, onThresholdsChange, canEditThresholds, onSplitOpen } = usePanelContext();
    let [rowModifier, setRowModifier] = useState<number>();
    // let events_attached = false
    const useQuery = () => new URLSearchParams(useLocation().search)
    // const panelChrome = this._reactInternalFiber._debugOwner.[REPEAT _debugOwner if needed].stateNode
    // const panelChrome = (this as any)._reactInternalFiber?._debugOwner?.stateNode;
    // const dashboard = panelChrome?.props?.dashboard;
    const query = useQuery();
    const theme = useTheme();
    const styles = getStyles();
    const [columnHightlighted, setColumnHightlighted] = useState<number>();
    const [omitValues, setOmitValues] = useState<number[]>([]);
    const [valuesOffset, _] = useState(0)
    const [searchValues, setSearchValues] = useState<{[key: number]: string}>({});

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const numRows   = urlParams.get("numRows");
    const orgId     = urlParams.get("orgId");
    const editPanel = urlParams.get("editPanel");
    const panelId   = urlParams.get("panelId")
    var highlightedIndex = -1
    const coords    = {x: 0, y: 0}
    const [currentdata, setCurrentdata] = useState(data)
    const listen_for_data = (event: any) => {
        // let empty: PanelData = {}
        setCurrentdata({} as PanelData);
        // window.addEventListener('new_data', listen_for_data, { once: true });
        data.series = event.detail.data as DataFrame[]
        // window.removeEventListener('new_data', listen_for_data, false)
        setCurrentdata(data);
        // let el = document.getElementsByClassName('cota_table_row') as unknown as HTMLTableRowElement
        // el.click()
    }
    if (!events_attached) {
        events_attached = true
        window.addEventListener('new_data', listen_for_data, true);
        window.addEventListener('mousemove', handleWindowMouseMove, false)
    }

    function generate_title(title: string, index: number) {
        let refactored_title = strip_text(title)
        if (check_value_existance(index, options.convertOptions?.weightColumns)) {
            return refactored_title + " " + (getMeasurementType()  ? "(gm)" : "(lb)")
        }
        return refactored_title
    }
    function handleWindowMouseMove (event: MouseEvent): void {
        coords.x = event?.clientX
        coords.y = event?.clientY
    }
    const handleRemoveButton = (rownum: any) => (event: any) => {
        setOmitValues((prevState: number[]) => {
            const clone = [...prevState];
            if(! prevState.includes(rownum)) { 
                clone.push(rownum)
            }
            return clone
        })
    }
    const handleChange = (event: React.ChangeEvent<FormControlElement>, rownum: number, label: string, name: string) => {
        if (label === "Filter") {
            setSearchValues({...searchValues, [rownum]: event.target.value})
        } else {
            if (event.target.value.length >= 2) {
                updateGrafanaValue(name, event.target.value)
                handleGrafanaMessage("search", generatePacket(rownum, {name: name, value: event.target.value}, "Hello from iframe"), undefined)
            }
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function getMeasurementType() {
        let get_measurement_type = query?.get("measurementType")?.toLowerCase()
        if (get_measurement_type !== undefined) {
            if (get_measurement_type === ("true" || "t")) {
                return true
            }
            if (get_measurement_type === ("false" || "f")) {
                return false
            }
        }
        return options.measurementType
    }
    function generatePacket(rownum: number, data: any, msg: any) {
        const packet_message = JSON.parse("{}") 
        if (options.includeDataPacket !== undefined) {
            packet_message.coords = coords
            packet_message.row  = rownum
            packet_message.data = data
            packet_message.panel_id = (panelId !== undefined) ? panelId : editPanel
            if (orgId !== undefined) {
                packet_message.org_id   = orgId
            }
        }
        if (msg !== undefined) {
            packet_message.message = msg
        }
        return packet_message
    }
    function reduceFieldsToIndex(rownum: number, fields: Field[]) {
        let data: any = {}
        fields.map((field: Field, i: number) => {
            data[field.name.toLowerCase()] = field.values.get(rownum)
        })
        return data
    }
    const grafanaRowClick = (rownum: number, data: any) => (event: any) => {
        if (options.rowOptions?.isTriggerable && !options.rowOptions?.disableRowTrigger) {
            handleGrafanaMessage("row", generatePacket(rownum, data, "Hello from iframe Row"), undefined)
        }
    }
    const grafanaRowMouseClick = (rownum: number, data: any, clicked: boolean) => (event: any) => {
        if (options.rowOptions?.mouseClickTriggers) {
            data.clicked = clicked
            handleGrafanaMessage("row_click", generatePacket(rownum, data, "Clicked on Grafan Row"), undefined)
        }
    }
    const grafanaButtonClick = (rownum: any, data: any) => (event: any) => {
        if (options.rowOptions?.isTriggerable) {
            handleGrafanaMessage("button", generatePacket(rownum, data, "Hello from iframe Button"), undefined)
        }
    }
    const handleGrafanaMessage = (channel: string, msg?: string | undefined, json?: any | undefined) => {
        let message = (msg !== undefined) ? msg : ""
        const packet = JSON.stringify({
            channel: "grafana_"+channel,
            message: (json === undefined) ? message : json,
            date: Date.now(),
        });
        window.parent.postMessage(packet, '*')
    }
    function returnHeader(fields: Field[]) {
        if (fields !== undefined) {
            fields = reorder_columns(fields)
            // setAliases({...searchValues, ["A-Series"]: "A - Series Passed"})
            return fields.map((field, i) => {
                let showColumn = true
                if (options.columnOptions?.columnsHide !== undefined) {
                    if (options.columnOptions?.columnsHide !== "") {
                        let values = options.columnOptions?.columnsHide.split(/[ ,]+/)
                        if (check_if_flagged(i, values, options.columnOptions?.columnsHide)) {
                            showColumn = false
                        }
                    }
                }
                if (options.columnOptions?.columnsShow !== undefined && (options.columnOptions?.columnsHide === undefined || options.columnOptions?.columnsHide === "")) {
                    if (options.columnOptions?.columnsShow !== "") {
                        let values = options.columnOptions?.columnsShow.split(/[ ,]+/)
                        if (!check_if_flagged(i, values, options.columnOptions?.columnsShow)) {
                            showColumn = false
                        }
                    }
                }
                if (showColumn) {
                    let column_title = (field?.state?.displayName !== undefined && field?.state?.displayName !== null) ? String(field?.state?.displayName) : field.name
                    return (<td key={"cota_table_cell_"+i} className={cx(
                        highlightColumn(i),
                        css`
                            min-width: ${get_column_width(i, options)};
                            max-width: ${get_column_width(i, options)};
                            width: 100%;
                            font-weight: ${check_title_bold(i, 0, options)};
                            color: ${get_column_color(i, options)};
                        `
                    )}>{generate_title(column_title, i)}{addColumnNumbers(i)}</td>)
                } else {
                    return (<></>)
                }
            });
        }
        return (<></>)
    }
    function check_title_bold(index: number, override: number, options: CotaOptions) {
        if (options.boldTitle) {
            return "600"
        }
        return convert_to_bold(index, override, options)
    }
    function addColumnNumbers(i: number) {
        if (options.columnOptions?.showColumnNumbers) {
            return " ("+i+")"
        }
        return ""
    }
    function returnFilterRow(fields: Field[]) {
        if (fields !== undefined) {
            fields = reorder_columns(fields)
            return fields.map((field, i) => 
                {
                    let showColumn = true
                    if (options.columnOptions?.columnsHide !== undefined) {
                        if (options.columnOptions?.columnsHide !== "") {
                            let values = options.columnOptions?.columnsHide.split(/[ ,]+/)
                            if (check_if_flagged(i, values, options.columnOptions?.columnsHide)) {
                                showColumn = false
                            }
                        }
                    }
                    if (options.columnOptions?.columnsShow !== undefined && (options.columnOptions?.columnsHide === undefined || options.columnOptions?.columnsHide === "")) {
                        if (options.columnOptions?.columnsShow !== "") {
                            let values = options.columnOptions?.columnsShow.split(/[ ,]+/)
                            if (!check_if_flagged(i, values, options.columnOptions?.columnsShow)) {
                                showColumn = false
                            }
                        }
                    }
                    if (showColumn) {
                        return (<td key={"cota_table_cell_"+i} className={cx(
                            highlightColumn(i),
                            css`
                                min-width: ${get_column_width(i, options)};
                                max-width: ${get_column_width(i, options)};
                                width: 100%;
                                font-weight: ${convert_to_bold(i, 0, options)};
                                color: ${get_column_color(i, options)};
                            `
                            )}>
                            {renderSearch(field, i)}
                        </td>)
                    } else {
                        return (<></>)
                    }
            }); 
        }
        return (<></>)
    }
    function renderSearch(field: Field, index: number) {
        // let v = getTemplateSrv().getVariables()
        let v = getGrafanaVariableValue(field.name)
        let label = "Filter"
        if (options.cotaConfigs?.useSearchFilters) {
            label = (v === undefined) ? "Filter" : "Search"
        }
        return (
            <Form>
                <Form.Group>
                    <Form.Control onChange={e => handleChange(e, index, label, field.name)} className='cota_search_cell cota_table_inputs' size="sm" type="text" placeholder={label} />
                </Form.Group>
            </Form>
        )
    }
    function returnRows(fields: Field[], values_length: number[]) {
        return [...values_length].map((_, index) =>
            <>
                {processRow(fields, index)}
            </>
        )
    }
    function reduceFields(fields: Field[]) {
        return fields;
    }
    function stubCells() {
        return (
            <>
            {options.addButtonOptions?.seriesAddButton && (<td className={cx('cota_table_cell'+get_cell_border(options),css`min-width: ${options.addButtonOptions.buttonColumnWidth}px`)}>&nbsp;
                </td>)}
            {options.seriesAddHide && (<td className={cx('cota_table_cell'+get_cell_border(options),css`min-width: ${options.hideRowColumnWidth}px`)}>&nbsp;
                </td>)}
            </>
        )
    }
    function processRow(fields: Field[], index: number) {
        let slicedData = fields
        slicedData = slicedData 
        if (options.devOptions?.resultsPerPage > -1) {
            slicedData = fields.slice(valuesOffset, options.devOptions?.resultsPerPage)
        }
        if (options.devOptions?.seriesDefaultLimit) {
            slicedData = fields.slice(valuesOffset, 10)
        }
        if (omitValues.indexOf(index) < 0) {
            return(<>
                    {processValues(fields, index)}
                </>)
        }
        return <></>
    }
    function reorder_columns(fields: Field[]) {
        if (fields !== undefined) {
            let new_fields = [...fields]
            let reduced_fields = [...fields]
            if (options.columnOptions?.reorderColumns !== undefined) {
                if (options.columnOptions?.reorderColumns !== "") {
                    let reordered_columns = options.columnOptions?.reorderColumns.split(/[ ,]+/)
                    let re_organized_fields: any[] = []
                    reordered_columns.map((column_num, i) => {
                        if (new_fields[Number(column_num)]) {
                            re_organized_fields.push(new_fields[Number(column_num)])
                            reduced_fields.splice(Number(column_num), Number(column_num)+1);
                        }
                    })
                    re_organized_fields = [...re_organized_fields, ...reduced_fields];
                    return re_organized_fields
                }
            }
        }
        return fields
    }
    function hightlight_cell_or_column(index: number) {
        let column_is_highlightened = highlightColumn(index)
        if (column_is_highlightened.includes('cota_table_cell_highlighted_column')) {
            return column_is_highlightened
        }
        return highlightCell()
    }
    function highlightColumn(index: number) {
        if (options.columnOptions?.hightlightColumn) {
            if (index === columnHightlighted) {
                return "cota_table_cell_highlighted_column"+get_cell_border(options)
            }
        }
        return "cota_table_cell"+get_cell_border(options)
    }
    function highlightCell() {
        if (options.highlightCell) {
            return "cota_table_cell_with_hover"+get_cell_border(options)
        }
        return "cota_table_cell"+get_cell_border(options)
    }
    function addHoverOptions() {
        if (options.rowOptions?.hightlightRow) {
            return " cota_table_row_add_hover"
        }
        if (options.rowOptions?.usePointer) {
            return " add_pointer"
        }
        return ""
    }
    function addRowNumberTitle(cell_text: string) {
        return <td className={'cota_table_cell row_numbers_cell'+get_cell_border(options)}>
                    {cell_text}
                </td>
    }
    function addRowNumbers(index: number) {
        return <td className={'cota_table_cell row_numbers_cell'+get_cell_border(options)}>
                    {index}
                </td>
    }
    function row_type() {
        if (options.cotaConfigs?.useCotaLgRows) {
            return " cota_table_row_border_lg"
        }
        return " "
    }
    const changeHighlightedIndex = (index: number) => (event: any) => {
        highlightedIndex = index
    }
    function processValues(fields: Field[], index: number) {
        if (fields !== undefined) {
            let showRow = true
            fields = reorder_columns(fields)
            fields.map((field: Field, i: number) => {
                if (searchValues[i] !== undefined) {
                    if (String(searchValues[i]) !== "") {
                        if (!(String(field.values.get(index)).toLowerCase().includes(String(searchValues[i]).toLowerCase()))) {
                            showRow = false
                        }
                    }
                }
            })
            // if (options.cotaConfigs?.cotaModalColumns !== undefined) {
            //     let values = options.cotaConfigs?.cotaModalColumns.split(/[, ]+/);
            //     let column_value = check_map_for_flagged(values, column_index, undefined)
            //     let column_values = column_value.split(/[-]/)
            //     if (column_values?.[0] !== "") {
            //         return (
            //             <>
            //                 <HiDotsVertical />
            //             </>
            //         )
            //     }
            // }
            if (showRow) {
                return (
                    <tr className={"cota_table_row"+addHoverOptions()+get_row_border(options)+row_type()} key={"cota_table_row_"+index} 
                        onClick={grafanaRowClick(index, reduceFieldsToIndex(index, fields))}
                        onMouseEnter={changeHighlightedIndex(index)}
                        onMouseUp={grafanaRowMouseClick(index, reduceFieldsToIndex(index, fields), true)} 
                        onMouseDown={grafanaRowMouseClick(index, reduceFieldsToIndex(index, fields), false)}>
                        {options.rowOptions?.includeRowNumbers && (
                            addRowNumbers(index)
                        )}
                        {(options.addButtonOptions?.seriesAddButton && !options.addButtonOptions?.seriesLeftRightButton) && (
                            processAddButtons(index, fields)
                        )}
                        {
                            fields.map((field: Field, i: number) => {
                                let showColumn = true
                                if (options.columnOptions?.columnsHide !== undefined) {
                                    if (options.columnOptions?.columnsHide !== "") {
                                        let values = options.columnOptions?.columnsHide.split(/[, ]+/)
                                        if (check_if_flagged(i, values, options.columnOptions?.columnsHide)) {
                                            showColumn = false
                                        }
                                    }
                                }
                                if (options.columnOptions?.columnsShow !== undefined && (options.columnOptions?.columnsHide === undefined || options.columnOptions?.columnsHide === "")) {
                                    if (options.columnOptions?.columnsShow !== "") {
                                        let values = options.columnOptions?.columnsShow.split(/[, ]+/)
                                        if (!check_if_flagged(i, values, options.columnOptions?.columnsShow)) {
                                            showColumn = false
                                        }
                                    }
                                }
                                let column_value = merge_column_values(index, i, options, field.values, fields)
                                if (showColumn) {
                                    return (
                                        <td className={cx(
                                            hightlight_cell_or_column(i),
                                            css`
                                                min-width: ${get_column_width(i, options)};
                                                max-width: ${get_column_width(i, options)};
                                                width: 100%;
                                                font-weight: ${convert_to_bold(i, 0, options)};
                                                font-style: ${convert_to_italic(i, 0, options)};
                                                color: ${check_column_color(i, options, column_value)};
                                            `
                                            )} key={"cota_table_cell_"+i+"_"+index} data-index={i.toString()} onMouseOver={inputMouseOverHandler}>
                                            {set_cell_type(column_value, i, index, fields)}
                                        </td>
                                    )
                                } else {
                                    return (<></>)
                                }
                            })
                        }
                        {(options.addButtonOptions?.seriesAddButton && options.addButtonOptions?.seriesLeftRightButton) && (
                            processAddButtons(index, fields)
                        )}
                        {options.seriesAddHide && (
                            processRemoveButtons(index)
                        )}
                        {options.addButtonOptions?.seriesMenuButton && (
                            processMenuButton(index)
                        )}
                    </tr>
                )
            } else {
                return <tr></tr>
            }
        }
        return <tr></tr>
    }
    const inputMouseOverHandler = (event: React.MouseEvent<HTMLTableCellElement>) => {
        const input: HTMLTableCellElement = event.currentTarget;
        setColumnHightlighted(Number(input.dataset?.index))
    };
    function merge_column_values(index: number, column_index: number, options: CotaOptions, values: any, fields: Field[]) {
        let column_value = values.get(index)
        if (options.columnOptions?.mergeColumns !== undefined) {
            if (options.columnOptions?.mergeColumns !== "") {
                let split_values = options.columnOptions?.mergeColumns.split(/[, ]+/)
                let column_options = check_map_for_flagged(split_values, column_index, undefined)
                if (column_options !== undefined) {
                    let columns = column_options.split(/[-]/)
                    columns.map((index_value, _) => {
                        if (is_number(index_value)) {
                            column_value = column_value+" "+fields[Number(index_value)]?.values.get(index)
                        }
                    })
                }
            }
        }
        return column_value
    }
    function check_column_color(index: number, options: CotaOptions, value: string) {
        let cell_color = get_column_color(index, options)
        if (options.colorOptions?.defaultColorValues !== undefined) {
            if (options.colorOptions?.defaultColorValues !== "") {
                let values = options.colorOptions?.defaultColorValues.split(/[, ]+/)
                let default_color_value = check_map_for_flagged(values, index, undefined)
                if (value === undefined || value === null) {
                    return default_color_value
                }
            }
        }
        return cell_color
    }
    function get_badge_class(class_name: string, opts: string | undefined) {
        let return_class = "ungrey-badge"
        if (opts !== undefined) {
            let badge_classes = opts.split(/[, ]+/);
            badge_classes.map((badge_class, _) => {
                let bc = badge_class.split(/[:]+/)
                if (bc[0].toLowerCase() === class_name.toLowerCase()) {
                    return_class = bc[1]
                }
            })
        }
        return return_class
    }
    function get_role_badges(value: string, opts: string | undefined) {
        let roles = value.split(/[, ]+/);
        return roles.map((role, index) => {
            return get_badge_icon(role, index, opts)
        })
    }
    function get_badge_icon(value: string, index: number, opts: string | undefined) {
        return <span key={"role_badge_"+index} className={'base_small_badge '+get_badge_class(value, options.columnOptions?.cotaRolesColors)}>
                    {render_badge_icon(value)}
                </span>
    }
    function render_badge_icon(value: string) {
        switch (value.toLowerCase()) {
            case "payor":
                return (<RiWallet3Line className='badge_icon'/>)
            case "consignee":
                return (<MdLocationPin className='badge_icon'/>)
            case "shipper":
                return (<GiTruck className='badge_icon'/>)
            default: 
                return <></>
                // return (<RiQuestionLine className='badge_icon'/>)
        }
    }
    function get_tertiary_color(row_index: number) {
        if (row_index === highlightedIndex) {
            return "cota_cell_tertiary_row_white"
        }
        return "cota_cell_tertiary_row_color"
    }
    function set_cell_type(value: string, column_index: number, row_index: number, fields: Field[]) {
        if (value !== undefined && value !== null) {
            if (options.cotaConfigs?.cotaDualColumns !== undefined) {
                let values = options.cotaConfigs?.cotaDualColumns.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                let column_values = column_value.split(/[-]/)
                if (column_values?.[0] !== "") {
                    let first_value = fields[Number(column_values?.[0])]?.values.get(row_index)
                    return (
                        <span>
                            <div className='cota_cell_main_row'>
                                {check_formatting(value, column_index)}
                            </div>
                            <div className={'cota_cell_tertiary_row '+get_tertiary_color(row_index)}>
                                {first_value}
                            </div>
                        </span>
                    )
                }
            }
            // highlightedIndex
            if (options.cotaConfigs?.cotaTriColumns !== undefined) {
                let values = options.cotaConfigs?.cotaTriColumns.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                let column_values = column_value.split(/[-]/)
                if (column_values?.[0] !== "") {
                    let first_value = fields[Number(column_values?.[0])]?.values.get(row_index)
                    let second_value = fields[Number(column_values?.[1])]?.values.get(row_index)
                    return (
                        <span>
                            <div className='cota_cell_main_row'>
                                {check_formatting(value, column_index)}
                            </div>
                            <div className='cota_cell_secondary_row'>
                                {first_value}
                            </div>
                            <div className={'cota_cell_tertiary_row '+get_tertiary_color(row_index)}>
                                {second_value}
                            </div>
                        </span>
                    )
                }
            }
            if (options.columnOptions?.cotaStatus !== undefined) {
                let values = options.columnOptions?.cotaStatus.split(/[, ]+/);
                if (check_if_flagged(column_index, values, options.columnOptions?.cotaStatus)) {
                    // let badge_class = options.columnOptions?.cotaStatusColors?.split(/[, ]+/);
                    // Available - Green Filled
                    // Delivered - Red Filled
                    // Billed - Red UN-filled
                    // Loaded - Yellow Filled
                    // Completed - Gray UN-filled
                    // Loaded - Green UN-filled
                    return <span className={'base_badge '+get_badge_class(value, options.columnOptions?.cotaStatusColors)}>
                                {value}
                           </span>
                }
            }
            if (options.columnOptions?.cotaRoles !== undefined) {
                let values = options.columnOptions?.cotaRoles.split(/[, ]+/);
                if (check_if_flagged(column_index, values, options.columnOptions?.cotaRoles)) {
                    return <span>
                        {
                            get_role_badges(value, options.columnOptions?.cotaRolesIcons)
                        }
                        </span>
                }
            }
            if (options.columnOptions?.cotaSources !== undefined) {
                let values = options.columnOptions?.cotaSources.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                if (column_value === undefined) {
                    return <span>
                            {value}
                        </span>
                }
            }
            if (options.columnOptions?.cotaProfiles !== undefined) {
                let values = options.columnOptions?.cotaProfiles.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                if (column_value === undefined) {
                    return <span>
                            {value}
                        </span>
                }
            }
            if (options.columnOptions?.clickableColumns !== undefined) {
                let values = options.columnOptions?.clickableColumns.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                if (column_value === undefined) {
                    return <span>
                            {value}
                        </span>
                }
            }
            if (options.cotaConfigs?.cotaIconColumn !== undefined) {
                let values = options.cotaConfigs?.cotaIconColumn.split(/[, ]+/);
                let column_value = check_map_for_flagged(values, column_index, undefined)
                let column_values = column_value.split(/[-]/)
                if (column_values?.[0] !== "") {
                    let first_value = fields[Number(column_values?.[0])]?.values.get(row_index)
                    return (
                        <>
                            <div className='profile_col_image'>
                                {/* <BsFillPersonFill /> */}
                            </div>
                            <div>
                                <div className='cota_cell_main_row'>
                                    {check_formatting(value, column_index)}
                                </div>
                                <div className={'cota_cell_tertiary_row '+get_tertiary_color(row_index)}>
                                    {first_value}
                                </div>
                            </div>
                        </>
                    )
                }
            }
            if (options.columnOptions?.editableCells !== undefined) {
                if (options.columnOptions?.editableCells !== "") {
                    let values = options.columnOptions?.editableCells.split(/[, ]+/);
                    let column_value = check_map_for_flagged(values, column_index, undefined)
                    let json_cell_limit: any = get_json_object(options.columnOptions?.editableCellsLimit, column_index, row_index)
                    let json_cell_types: any = get_json_object(options.columnOptions?.editableCellsValues, column_index, row_index)
                    let max_length = -1
                    if (json_cell_limit !== undefined) {
                        if (json_cell_limit["limit"] !== undefined) {
                            max_length = json_cell_limit["limit"] 
                        }
                    }
                    let input_type = "text"
                    if (json_cell_types !== undefined) {
                        if (json_cell_types["type"] !== undefined) {
                            input_type = json_cell_types["type"]
                        }
                    }
                    if (column_value === undefined) {
                        return <input className="input_field"
                                        id={"input_"+row_index+"_"+column_index}
                                        name={"input_"+row_index+"_"+column_index}
                                        type={input_type} 
                                        maxLength={max_length} 
                                        onChange={handle_cell_change()} />
                    }
                }
            }
            if (options.columnOptions?.dropdownCells !== undefined) {
                if (options.columnOptions?.dropdownCells !== "") {
                    let values = options.columnOptions?.dropdownCells.split(/[, ]+/);
                    let column_value = check_map_for_flagged(values, column_index, undefined)
                    if (options.columnOptions?.dropdownCellValues !== undefined) {
                        if (options.columnOptions?.dropdownCellValues !== "") {
                            let json: any = JSON.parse(options.columnOptions?.dropdownCellValues as string)
                            if (column_value === undefined) {
                                return (
                                    <select className="input_field" 
                                        id={"input_"+row_index+"_"+column_index}
                                        name={"input_"+row_index+"_"+column_index}
                                        onChange={handle_cell_change()}>
                                        {
                                            process_dropdown(json, column_index, row_index)
                                        }
                                    </select>
                                )
                            }
                        }
                    }
                }
            }
        }
        return check_formatting(value, column_index)
    }
    function get_json_object(value_string: any, column_index: number, row_index: number) {
        let json: any = undefined
        let column_object: any = undefined
        if (value_string !== undefined) {
            if (value_string !== "") {
                json = JSON.parse(value_string as string)
            }
        }
        if (json !== undefined) {
            column_object = json[column_index.toString()]
        }
        if (column_object !== undefined) {
            if (column_object[row_index] !== undefined) {
                return column_object[row_index]
            }
        }
        return column_object
    }
    function process_dropdown(json: any, column_index: number, row_index: number) {
        let column_object = json[column_index.toString()]
        let dropdown_data = column_object
        if (column_object !== undefined) {
            if (column_object[row_index] !== undefined) {
                dropdown_data = column_object[row_index]
            }
            if (dropdown_data !== undefined) {
                if (dropdown_data["keys"] !== undefined) {
                    return dropdown_data["keys"].map((element: string, index: number) => {
                        return <option key={index}>{element}</option>
                    })
                }
            }
        }
        return <option>Missing Values</option>
    }
    const handle_cell_change = () => (event: any) => {
        const formElement = document.querySelector('form')
        const result = getFormJSON(formElement)
        let transformed_result: any = {};
        Object.keys(result).forEach(function(key, index) {
            let parsed_key = key.split(/[_]+/);
            if (transformed_result?.[parsed_key[1]] === undefined) {
                transformed_result[parsed_key[1]] = {}
            }
            let sub_key = String(parsed_key[2])
            let new_obj: {[k: string]: any} = {[sub_key] : result["input_"+parsed_key[1]+"_"+sub_key]};
            let obj = transformed_result[parsed_key[1]]
            obj = { ...obj, ...new_obj }
            transformed_result[parsed_key[1]] = obj
        })
        let last_obj = transformed_result[Object.keys(transformed_result).length-1]
        let check_val = 0
        Object.values(last_obj).map(function(val, index) {
            if (val !== "") {
                check_val = check_val+1
            }
        })
        if (check_val === Object.values(last_obj).length) {
            if (options.devOptions?.autoRow) {
                // row_modifier = row_modifier+1
                if (rowModifier === undefined) {
                    setRowModifier(0)
                }
                setRowModifier(Number(rowModifier)+1)
            }
        }
        transformed_result["row_modifier"] = rowModifier
        handleGrafanaMessage("form", undefined, transformed_result) 
    }
    const getFormJSON = (form: any) => {
        const data = new FormData(form);
        return Array.from(data.keys()).reduce((result: any , key) => {
            result[key] = data.get(key);
            return result;
        }, {});
    };
    function check_formatting(value: string, index: number) {
        if (value !== undefined && value !== null) {
            value = value.toString()
            if (options.convertOptions?.truncateColumn !== undefined) {
                if (options.convertOptions?.truncateColumn !== "") {
                    let values = options.convertOptions?.truncateColumn.split(/[, ]+/);
                    let truncate_value = check_map_for_flagged(values, index, undefined)
                    if (truncate_value !== undefined) {
                        value = truncate_string(value, truncate_value)
                    }
                }
            }
            value = convert_value(value, index, options.convertOptions?.phoneNumberColumns, convert_to_phone)
            value = convert_value(value, index, options.convertOptions?.decimalColumns, convert_to_decimal)
            value = convert_value(value, index, options.convertOptions?.percentColumns, convert_to_percent)
            value = convert_value(value, index, options.convertOptions?.currencyColumns, convert_to_currency)
            if (check_value_existance(index, options.convertOptions?.weightColumns)) {
                value = convert_weight(value, getMeasurementType()) + format_weight(value, getMeasurementType())
            }
            if (check_value_existance(index, options.convertOptions?.lengthColumns)) {
                value = convert_length(value, getMeasurementType()) + format_length(value, getMeasurementType())
            }
            if (check_value_existance(index, options.convertOptions?.speedColumns)) {
                value = convert_speed(value, getMeasurementType()) + format_speed(value, getMeasurementType())
            }
            if (check_value_existance(index, options.convertOptions?.tempColumns)) {
                value = convert_temperature(value, getMeasurementType()) + format_temperature(value, getMeasurementType())
            }
        } else {
            let values = options.columnOptions?.defaultColumnValues.split(/[,]/);
            value = check_map_for_flagged(values, index, undefined)
        }
        return value
    }
    function convert_value(value: string, index: number, option_string: string | undefined, convert_function: Function) {
        if (check_value_existance(index, option_string)) {
            return convert_function(value) 
        }
        return value
    }
    function processMenuButton(rownum: number) {
        return (<></>)
        // return (<td>
        //             <div className="menu_class" onClick={show_menu(rownum)}>
        //                 <HiDotsVertical />
        //             </div>
        //         </td>)
    }
    function processAddButtons(rownum: number, fields: Field[]) {
        return <td className={'cota_table_cell button_column_width'+get_cell_border(options)}>
            <Button className="cota_table_button" variant="outline-light" onClick={grafanaButtonClick(rownum, reduceFieldsToIndex(rownum, fields))}>
                {options.addButtonOptions?.seriesAddButtonText}
            </Button>{' '}
        </td>
    }
    function processRemoveButtons(rownum: number) {
        return <td className={'cota_table_cell x_column_width'+get_cell_border(options)}>
            <Button className="cota_table_remove_button" variant="outline-light" onClick={handleRemoveButton(rownum)}>
                X
            </Button>{' '}
        </td>
    }
    function load_table(series: DataFrame) {
        let fields = reduceFields(series.fields)
        let return_rows = returnRows(fields, Array(series.length))
        handleGrafanaMessage("loader", generatePacket(0, undefined, "Loading Grafana Data"), undefined)
        return (<>
            {return_rows}
        </>);
    }
    function load_header(series: DataFrame, index: number) {
        if (options.includeTitle || options.onlySearch) {
            return (
                <>
                    <thead className={get_header_css(options)} key={"cota_header_table_"+index}>
                        <tr className={"cota_table_header cota_bold cota_table_row"+ get_row_border(options)} key={"cota_table_header"}>
                            {options.rowOptions?.includeRowNumbers && (
                                addRowNumberTitle("Row Numbers")
                            )}
                            {options.includeTitle  && (
                                returnHeader(series.fields)
                            )}
                            {stubCells()}
                        </tr>
                        {load_search_row(series)}
                        {load_paging()}
                    </thead>
                </>
            )
        }
        return (<></>)
    }
    function load_paging() {
        if (options.cotaConfigs?.useCotaTopPaging) {
            return (
                <>
                    <tr className='cota_table_row_border cota_table_row cota_table_row_padding'>
                        <td>
                            <Button className="button-skin" type="button" aria-label="previous" onClick={nav_pagination(false)}>
                                <span className="css-1mhnkuh">
                                    <div className="css-wf08df-Icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="30" height="30" className="arrows css-eyx4do">
                                            <path d="M11.29,12l3.54-3.54a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L9.17,11.29a1,1,0,0,0,0,1.42L13.41,17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41Z" />
                                        </svg>
                                    </div>
                                </span>
                            </Button>
                        </td>
                        <td />
                        <td>
                            <Button className="button-skin" type="button" aria-label="next" onClick={nav_pagination(true)}>
                                <span className="css-1mhnkuh">
                                    <div className="css-wf08df-Icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="30" height="30" className="arrows css-eyx4do">
                                            <path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"/>
                                        </svg>
                                    </div>
                                </span>
                            </Button>
                        </td>
                    </tr>
                </>
            )
        }
        return (<></>)
    }
    const nav_pagination = (direction: boolean) => (event: any) => {
        const queryString   = window.location.search;
        const urlParams     = new URLSearchParams(queryString);
        let new_var         = urlParams.get("var-page")
        // let v = getTemplateSrv().getVariables().find(({ name }) => name === "page") as ExtendedVariableModel
        let v = getGrafanaVariableValue("page")
        let selected = JSON.parse(JSON.stringify(v))
        if (selected?.current?.value !== new_var) {
            if (new_var !== null && new_var !== undefined) {
                selected.current.value = String(new_var)
            }
        }
        if (direction) {
            if (!Number.isNaN(Number(selected.current.value))) {
                if (!isNaN(Number(selected.current.value))) {
                    let new_page = Number(selected.current.value)+1
                    selected.current.value = String(new_page)
                    updateGrafanaValue("page", new_page)
                    handleGrafanaMessage("nav", generatePacket(new_page, {direction: direction, page: new_page}, "Hello from iframe Button"), undefined)
                }
            }
        } else {
            if (!Number.isNaN(Number(selected.current.value))) {
                let page_number = Number(selected.current.value)
                if (!isNaN(Number(selected.current.value))) {
                    let new_page = ((page_number-1 < 0) ? 0 : page_number-1)
                    selected.current.value = String(new_page)
                    updateGrafanaValue("page", new_page)
                    handleGrafanaMessage("nav", generatePacket(new_page, {direction: direction, page: new_page}, "Hello from iframe Button"), undefined)
                }
            }
        }
    }
    function updateGrafanaValue(variableName: String, value: Number | String) {
       locationService.partial(
           {
               [`var-${variableName}`]: value,
           },
           true // replace: true tells Grafana to update the current URL state, rather than creating a new history entry.
       );
    }
    function getGrafanaVariableValue(variableName: string) {
       return getTemplateSrv().getVariables().find(({ name }) => name === variableName) as ExtendedVariableModel
    }

    function load_search_row(series: DataFrame) {
        if (!options.disableSearch) {
            return (
                <>
                    <tr className={"cota_table_header cota_table_row "+ get_row_border(options)} key={"cota_table_header"}>
                        {options.rowOptions?.includeRowNumbers && (
                                addRowNumberTitle("")
                        )}
                        {returnFilterRow(series.fields)}
                        {stubCells()}
                    </tr>
                </>
            )
        }
        return (<></>)
    }
    function map_data(data: any) {
        if (options.devOptions?.isForm) {
            // const queryString = window.location.search;
            // const urlParams = new URLSearchParams(queryString);
            // const numRows = urlParams.get("numRows");
            // const orgId = urlParams.get("orgId");
            // const editPanel = urlParams.get("editPanel");
            // const panelId = urlParams.get("panelId");
            // const userParam = urlParams.get("user");
            // const customerParam = urlParams.get("customer");
            // const stParam = urlParams.get("st");
            // const etParam = urlParams.get("et");
            let number_of_rows = 0
            if (numRows !== undefined && numRows !== null) {
                number_of_rows = Number(numRows)
            } else {
                number_of_rows = (options.devOptions?.numberOfRows !== undefined) ? options.devOptions?.numberOfRows : number_of_rows
            }
            if (options.devOptions?.autoRow) {
                if (rowModifier === undefined) {
                    setRowModifier(0)
                }
                number_of_rows = number_of_rows+Number(rowModifier)
            }
            let number_of_cols = (options.devOptions?.numberOfColumns !== undefined) ? options.devOptions?.numberOfColumns : 0
            let titles: string[] = new Array<string>(number_of_cols)
            titles.fill("")
            let formTitles = (options.devOptions?.formTitles !== undefined) ? options.devOptions?.formTitles : ""
            if (formTitles !== "") {
                var splitted = formTitles.split(",");
                for (let i=0; i < splitted.length; i++) {
                    titles[i] = splitted[i];
                }
            }
            if (number_of_rows < 1 || isNaN(Number(number_of_rows))) {
                number_of_rows = 1
            }
            let limited_rows = new Array<string>(number_of_rows)
            limited_rows.fill("0")
            let field_array = new Array<any>(number_of_cols)
            return (
                data.series.map((series: DataFrame, i: number) => {
                    let stub_array   = new Array<any>(number_of_cols)
                    stub_array.fill("0")
                    stub_array.map((field, index) => {
                        let field_title = (titles?.[index] !== undefined) ? titles[index] : ""
                       	field_array[index] = { name: field_title, type: FieldType.string, values: limited_rows}
                    })
                    const frame = toDataFrame({
                        name: 'form_data',
                        fields: field_array
                    });
                    return (
                        <React.Fragment key={i}>
                            <div>
                            </div>
                            <div className="cota_data_table">
                                <form name="forms" id="forms" className='forms'>
                                    <table className='cota_table' key={"cota_data_table_"+i}>
                                        {load_header(frame, i)}
                                        <tbody className='cota_tbody cota_table_width_adjustment'>
                                            {load_table(frame)}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </React.Fragment>
                    )
                })
            )
        } else {
            if (data?.series !== undefined) {
                return data?.series?.map((series: DataFrame, i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <div>
                            </div>
                            <div className="cota_data_table">
                            {/* <div className="cota_data_table" title={JSON.stringify(series)}> */}
                                <form name="forms" id="forms" className='forms'>
                                    <table className='cota_table' key={"cota_data_table_"+i}>
                                        {load_header(series, i)}
                                        <tbody className='cota_tbody cota_table_width_adjustment'>
                                            {load_table(series)}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </React.Fragment>
                        )
                })
            }
        }
    }
    // Base Return. Start Here
    return (
        <div
            className={cx(
                styles.wrapper,
                css`
                    width: 100%;
                    height: 100%;
                `
            )}
            >
        <div className="cota_table_wrapper" id={""+currentdata+""}>
        {
            <>
                {map_data(currentdata)}
            </>
        }
        {options.showSeriesCount && (
          <div
            className={css`
              font-size: ${theme.typography.size[options.seriesCountSize]};
            `}
            >
            Number of series: 
            {
                currentdata.series.map((series, index ) => {
                    return series.length
                })
            }
          </div>
        )}
        </div>
    </div>
    );
};

const getStyles = stylesFactory(() => {
    return {
        wrapper: css`
            position: relative;
        `,
        textBox: css`
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
        `,
    };
});
