export interface ColorValues {
    hightlightRowColor?: string;
    hightlightColumnColor?: string;
    defaultColumnColor?: string;
    columnsEachColor?: string;
    defaultColorValues?: string;
    headerColor?: string;
}
export const defaultColorValues: ColorValues = {
    hightlightRowColor: "lightsteelblue",
    hightlightColumnColor: "lightsteelblue",
    defaultColumnColor: "#043b64",
    columnsEachColor: "",
    defaultColorValues: "",
    headerColor: ""
}
