export interface CotaValues {
    cotaDualColumns?: string;
    cotaTriColumns?: string;
    cotaIconColumn?: string;
    cotaModalColumns?: string;
    useCotaLgRows?: boolean;
    useCotaTopPaging?: boolean;
    useCotaBottomPaging?: boolean;
    cotaSearchFields?: string;
    useSearchFilters?: boolean;
}
export const cota_values_cfg: CotaValues = {
    cotaDualColumns: "",
    cotaTriColumns: "",
    cotaIconColumn: "",
    cotaModalColumns: "",
    useCotaLgRows: true,
    useCotaTopPaging: true,
    useCotaBottomPaging: true,
    cotaSearchFields: "",
    useSearchFilters: false
}
