import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import {  PanelProps } from '@grafana/data';
import { CotaFormOptions } from 'types';
import { css } from 'emotion';
import { stylesFactory, useTheme } from '@grafana/ui';
// import { Button, Form } from 'react-bootstrap';
// import { check_if_flagged, strip_text, check_map_for_flagged, check_value_existance, truncate_string, is_number } from './partials/ViewController/UtilityFunctions';
// import { get_column_width, get_column_color, get_header_css, get_row_border, get_cell_border } from "./partials/ViewController/ViewFunctions"
// import { convert_to_bold, convert_to_italic, convert_to_currency, convert_to_phone, convert_to_decimal, convert_to_percent, convert_weight, convert_length, convert_speed, convert_temperature} from 'partials/ViewController/ConvertFunctions';
// import { format_weight, format_length, format_speed, format_temperature } from "./partials/ViewController/FormatFunctions"

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
// import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import "./styles/cota_panel.css"
// import "./styles/cota_iframes.css"

interface Props extends PanelProps<CotaFormOptions> {
}

if (window.top !== window.self) {
    require('./styles/cota_iframe.css');
}

// { options, data, timeZone, timeRange, width, height, replaceVariables }
export const FormCotaPanel: React.FC<Props> = ({ options, data, timeZone, timeRange, width, height, replaceVariables }) => {
    // const { sync, canAddAnnotations, onThresholdsChange, canEditThresholds, onSplitOpen } = usePanelContext();
    const useQuery = () => new URLSearchParams(useLocation().search)
    let stash = undefined
    console.log(stash)
    const query = useQuery();
    const theme = useTheme();
    const styles = getStyles();
    const [columnHightlighted, setColumnHightlighted] = useState<number>();
    const [omitValues, setOmitValues] = useState<number[]>([]);
    const [valuesOffset, _] = useState(0)
    const [searchValues, setSearchValues] = useState<{[key: number]: string}>({});
    stash = query;
    stash = theme;
    stash = styles;
    stash = columnHightlighted;
    stash = setColumnHightlighted;
    stash = omitValues;
    stash = setOmitValues;
    stash = valuesOffset;
    stash = searchValues;
    stash = setSearchValues;
    return (<></>)
};

const getStyles = stylesFactory(() => {
    return {
        wrapper: css`
            position: relative;
        `,
        textBox: css`
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
        `,
    };
});
