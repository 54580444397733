export interface DevValues {
    resultsPerPage: number;
    seriesDefaultLimit?: boolean;
    adjustPadding: number;
    numberOfColumns?: number;
    numberOfRows?: number;
    isForm: boolean;
    autoRow: boolean;
    formTitles?: string;
}
export const defaultDevValues: DevValues = {
    resultsPerPage: -1,
    seriesDefaultLimit: false,
    adjustPadding: -1,
    numberOfColumns: 0,
    numberOfRows: 1,
    isForm: false,
    autoRow: false,
    formTitles: ""
};
