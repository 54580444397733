import { PanelPlugin } from '@grafana/data';
import { CotaOptions, CotaFormOptions } from './types';
import { SimpleCotaPanel } from './SimpleCotaPanel';
import { FormCotaPanel } from "./FormCotaPanel"

// export interface ButtonValues {
//     seriesAddButton?: boolean;
//     seriesAddButtonText?: string;
// }
// export const defaultButtonValues: ButtonValues = {
//     seriesAddButton: false,
//     seriesAddButtonText: "Book It"
// };

// export interface RowValues {
//     hightlightRow?: boolean;
//     hightlightRowColor?: string;
//     usePointer?: boolean;
//     disableRowTrigger?: boolean;
//     isTriggerable?: boolean;
// }
// export const defaultRowValues: RowValues = {
//     hightlightRow: false,
//     hightlightRowColor: "lightsteelblue",
//     usePointer: false,
//     disableRowTrigger: false,
//     isTriggerable: false
// };
import { defaultButtonValues } from "objects/ButtonValues"
import { defaultColorValues } from 'objects/ColorValues';
import { defaultColumnValues } from "objects/ColumnValues"
import { defaultConvertValues } from 'objects/ConvertValues';
import { defaultDevValues } from "objects/DevValues"
import { defaultRowValues } from "objects/RowValues"
import { cota_values_cfg } from "objects/CotaValues"

export const formplugin = new PanelPlugin<CotaFormOptions>(FormCotaPanel)
    .setPanelOptions(builder => {
        const dev_values_cfg    = defaultDevValues;
        const convert_values_cfg = defaultConvertValues;
        return builder
        .addNestedOptions({
            path: "devOptions",
            category: ['Form'],
            defaultValue: dev_values_cfg,
            build: (builder) => {
                builder
                .addBooleanSwitch({
                    path: 'isForm',
                    name: 'Grafana Panel is Form',
                    description: 'Grafana Panel is Form',
                    defaultValue: dev_values_cfg.isForm
                })
            }
        })
        .addNestedOptions({
            path: "convertOptions",
            category: ['Value Conversion Options'],
            defaultValue: convert_values_cfg,
            build: (builder) => {
                builder
                .addTextInput({
                    path: "aliasColumnValues",
                    name: "Alias Column Values",
                    description: "Change text of values only for specified Columns",
                    defaultValue: convert_values_cfg.aliasColumnValues
                })
                .addTextInput({
                    path: "currencyColumns",
                    name: "Currency Columns",
                    description: "Columns to change to currency (0 Indexed)",
                    defaultValue: convert_values_cfg.currencyColumns
                })
                .addTextInput({
                    path: "phoneNumberColumns",
                    name: "Phone Number Columns",
                    description: "Columns to covernt to Phone Numbers (0 Indexed)",
                    defaultValue: convert_values_cfg.phoneNumberColumns
                })
                .addTextInput({
                    path: "percentColumns",
                    name: "Percent Number Columns",
                    description: "Columns to covernt to a Percent. Adds a %, and truncates to 2 places uncless otherwise specified",
                    defaultValue: convert_values_cfg.percentColumns
                })
                .addTextInput({
                    path: "decimalColumns",
                    name: "Decimal Number Columns",
                    description: "Auto Covert to X.XX (Truncate string to 2 places unless otherwise specified, adds a decimal point if necessary)",
                    defaultValue: convert_values_cfg.decimalColumns
                })
                .addTextInput({
                    path: "weightColumns",
                    name: "Weight Columns",
                    description: "Auto Covert a column to Weight",
                    defaultValue: convert_values_cfg.weightColumns
                })
                .addTextInput({
                    path: "lengthColumns",
                    name: "Length Number Columns",
                    description: "Auto Covert a column to Length",
                    defaultValue: convert_values_cfg.lengthColumns
                })
                .addTextInput({
                    path: "speedColumns",
                    name: "Speed Number Columns",
                    description: "Auto Covert to Velocity",
                    defaultValue: convert_values_cfg.speedColumns
                })
                .addTextInput({
                    path: "tempColumns",
                    name: "Temperature Number Columns",
                    description: "Auto Covert to Temperature",
                    defaultValue: convert_values_cfg.tempColumns
                })
                .addTextInput({
                    path: "truncateColumn",
                    name: "Truncate Columns",
                    description: "Truncates columns to X number of places (0 Indexed) (e.g: 0:2,1:3,2:1,3-5:2)",
                    defaultValue: convert_values_cfg.truncateColumn
                })
            }
        })
    }
);

export const plugin = new PanelPlugin<CotaOptions>(SimpleCotaPanel)
    .setPanelOptions(builder => {
        const button_values_cfg = defaultButtonValues;
        const color_values_cfg  = defaultColorValues;
        const column_values_cfg = defaultColumnValues;
        const convert_values_cfg = defaultConvertValues;
        const dev_values_cfg    = defaultDevValues;
        const row_values_cfg    = defaultRowValues;
        return builder
        .addTextInput({
            path: "aliasValues",
            name: "Alias Panel Values",
            description: "Change text of value any value in the panel",
            defaultValue: ""
        })
        .addBooleanSwitch({
            path: "boldTitle",
            name: "Make Titles Bold",
            description: "Make Titles Bold.",
            defaultValue: true
        })
        .addBooleanSwitch({
            path: "measurementType",
            name: "Toggle between Imperial or Metric system",
            description: "Toggle between Imperial or Metric system",
            defaultValue: true
        })
        .addBooleanSwitch({
            path: "highlightCell",
            name: "Hightlight Individual Cell",
            description: "",
            defaultValue: false
        })
        .addBooleanSwitch({
            path: "includeDataPacket",
            name: "Include Data in Packet",
            description: "Include a json data packet to the parent frame in any button or row based trigger",
            defaultValue: false
        })
        .addBooleanSwitch({
            path: "includeTitle",
            name: "Include Title & Search",
            description: "Toggle the table header, which includes the title of the columns and the headers.",
            defaultValue: true
        })
        .addBooleanSwitch({
            path: "disableSearch",
            name: "Disable Search from Header",
            description: "Remove the Search Bar row from the table.",
            defaultValue: false
        })
        .addBooleanSwitch({
            path: "onlySearch",
            name: "Include Only Search",
            description: "TOnly display Search Bar in the header",
            defaultValue: true
        })
        .addBooleanSwitch({
            path: 'showSeriesCount',
            name: 'Show Series Counter',
            description: "show the series counter.",
            defaultValue: false,
        })
        .addNestedOptions({
            path: "devOptions",
            category: ['Under Development'],
            defaultValue: dev_values_cfg,
            build: (builder) => {
                builder
                    .addNumberInput({
                        path: 'resultsPerPage',
                        name: 'Length of Results Per Page',
                        description: "This sets the maximum number of results per page",
                        defaultValue: dev_values_cfg.resultsPerPage
                    })
                    .addBooleanSwitch({
                        path: 'seriesDefaultLimit',
                        name: 'Toggle Default Page Limit',
                        description: "This toggles the limit to the number of rows for pages",
                        defaultValue: dev_values_cfg.seriesDefaultLimit,
                    })
                    .addNumberInput({
                        path: 'adjustPadding',
                        name: 'Adjust Padding',
                        description: "Adjust the padding of the table",
                        defaultValue: dev_values_cfg.adjustPadding,
                    })
                    .addBooleanSwitch({
                        path: 'isForm',
                        name: 'Grafana Page is Form',
                        description: 'Toggle for activation of Adding Button to the Table.',
                        defaultValue: dev_values_cfg.isForm
                    })
                    .addBooleanSwitch({
                        path: 'autoRow',
                        name: 'Automatically add new row as filled',
                        description: 'Automatically add new row as filled.',
                        defaultValue: dev_values_cfg.autoRow
                    })
                    .addTextInput({
                        path: "formTitles",
                        name: "Form Titles",
                        description: "Titles for Form Columns.",
                        defaultValue: dev_values_cfg.formTitles
                    })
                    .addNumberInput({
                        path: "numberOfRows",
                        name: "Number of Rows for Form or Panel",
                        description: "Number of Rows for Form or Panel",
                        defaultValue: dev_values_cfg.numberOfRows
                    })
                    .addNumberInput({
                        path: "numberOfColumns",
                        name: "Number of Columns for Form or Panel",
                        description: "Number of Columns for Form or Panel",
                        defaultValue: dev_values_cfg.numberOfColumns
                    })
            }
        })
        .addNestedOptions({
            path: "inDevelopment",
            category: ['Under Development'],
            defaultValue: dev_values_cfg,
            build: (builder) => {

            }
        })
        .addNestedOptions({
            path: "addButtonOptions",
            category: ['Button Options'],
            defaultValue: button_values_cfg,
            build: (builder) => {
                builder
                    .addBooleanSwitch({
                        path: 'seriesAddButton',
                        name: 'Show Add Button',
                        description: 'Toggle for activation of Adding Button to the Table.',
                        defaultValue: button_values_cfg.seriesAddButton
                    })
                    .addBooleanSwitch({
                        path: 'seriesLeftRightButton',
                        name: 'Button Left or Right',
                        description: 'Button Left or Right.',
                        defaultValue: button_values_cfg.seriesLeftRightButton
                    })
                    .addTextInput({
                        path: 'seriesAddButtonText',
                        name: 'Show Add Button Text',
                        description: 'Change the default text of the button',
                        defaultValue: button_values_cfg.seriesAddButtonText
                    })
                    .addBooleanSwitch({
                        path: 'seriesMenuButton',
                        name: 'Show Menu Button',
                        description: 'Toggle for activation of Adding Menu Button to the Table.',
                        defaultValue: button_values_cfg.seriesMenuButton
                    })
                    .addTextInput({
                        path: 'seriesMenuButtonText',
                        name: 'Show Add Button Text',
                        description: 'Change the default text of the Menu Button',
                        defaultValue: button_values_cfg.seriesMenuButtonText
                    })
                    .addNumberInput({
                        path: "buttonColumnWidth",
                        name: "Button Columns Min Width",
                        description: "Set the standard mininum width of the button column",
                        defaultValue: button_values_cfg.buttonColumnWidth
                    })
            }
        })
        .addNestedOptions({
            path: "cotaConfigs",
            category: ['Cota Column Options'],
            defaultValue: convert_values_cfg,
            build: (builder) => {
                builder
                .addBooleanSwitch({
                    path: 'useCotaLgRows',
                    name: 'Use Large Cota Rows',
                    description: "Use Large Cota Rows",
                    defaultValue: cota_values_cfg.useCotaLgRows,
                })
                .addBooleanSwitch({
                    path: 'useCotaTopPaging',
                    name: 'Use API Paging (Top)',
                    description: "Use API Paging (Top)",
                    defaultValue: cota_values_cfg.useCotaTopPaging,
                })
                .addBooleanSwitch({
                    path: 'useCotaBottomPaging',
                    name: 'Use API Paging (Bottom)',
                    description: "Use API Paging (Bottom)",
                    defaultValue: cota_values_cfg.useCotaBottomPaging,
                })
                .addTextInput({
                    path: "cotaDualColumns",
                    name: "Cota Dual Columns",
                    description: "Cota Dual Columns",
                    defaultValue: cota_values_cfg.cotaDualColumns
                })
                .addTextInput({
                    path: "cotaTriColumns",
                    name: "Cota Tri Columns",
                    description: "Cota Tri Columns",
                    defaultValue: cota_values_cfg.cotaTriColumns
                })
                .addTextInput({
                    path: "cotaIconColumn",
                    name: "Cota Icon Columns",
                    description: "Cota Icon Columns",
                    defaultValue: cota_values_cfg.cotaIconColumn
                })
                .addTextInput({
                    path: "cotaModalColumns",
                    name: "Cota Modal Columns",
                    description: "Cota Modal Columns",
                    defaultValue: cota_values_cfg.cotaModalColumns
                })
                .addTextInput({
                    path: "cotaSearchFields",
                    name: "Bind Search Fields to the Cota API",
                    description: "Bind Search Fields to the Cota API",
                    defaultValue: cota_values_cfg.cotaSearchFields
                })
                .addBooleanSwitch({
                    path: 'useSearchFilters',
                    name: 'use Search Filters',
                    description: "Link the filers to a search function against the Data Source",
                    defaultValue: false,
                })
            }
        })
        .addNestedOptions({
            path: "convertOptions",
            category: ['Value Conversion Options'],
            defaultValue: convert_values_cfg,
            build: (builder) => {
                builder
                .addTextInput({
                    path: "aliasColumnValues",
                    name: "Alias Column Values",
                    description: "Change text of values only for specified Columns",
                    defaultValue: convert_values_cfg.aliasColumnValues
                })
                .addTextInput({
                    path: "currencyColumns",
                    name: "Currency Columns",
                    description: "Columns to change to currency (0 Indexed)",
                    defaultValue: convert_values_cfg.currencyColumns
                })
                .addTextInput({
                    path: "phoneNumberColumns",
                    name: "Phone Number Columns",
                    description: "Columns to covernt to Phone Numbers (0 Indexed)",
                    defaultValue: convert_values_cfg.phoneNumberColumns
                })
                .addTextInput({
                    path: "percentColumns",
                    name: "Percent Number Columns",
                    description: "Columns to covernt to a Percent. Adds a %, and truncates to 2 places uncless otherwise specified",
                    defaultValue: convert_values_cfg.percentColumns
                })
                .addTextInput({
                    path: "decimalColumns",
                    name: "Decimal Number Columns",
                    description: "Auto Covert to X.XX (Truncate string to 2 places unless otherwise specified, adds a decimal point if necessary)",
                    defaultValue: convert_values_cfg.decimalColumns
                })
                .addTextInput({
                    path: "weightColumns",
                    name: "Weight Columns",
                    description: "Auto Covert a column to Weight",
                    defaultValue: convert_values_cfg.weightColumns
                })
                .addTextInput({
                    path: "lengthColumns",
                    name: "Length Number Columns",
                    description: "Auto Covert a column to Length",
                    defaultValue: convert_values_cfg.lengthColumns
                })
                .addTextInput({
                    path: "speedColumns",
                    name: "Speed Number Columns",
                    description: "Auto Covert to Velocity",
                    defaultValue: convert_values_cfg.speedColumns
                })
                .addTextInput({
                    path: "tempColumns",
                    name: "Temperature Number Columns",
                    description: "Auto Covert to Temperature",
                    defaultValue: convert_values_cfg.tempColumns
                })
                .addTextInput({
                    path: "truncateColumn",
                    name: "Truncate Columns",
                    description: "Truncates columns to X number of places (0 Indexed) (e.g: 0:2,1:3,2:1,3-5:2)",
                    defaultValue: convert_values_cfg.truncateColumn
                })
            }
        })
        .addNestedOptions({
            path: "columnOptions",
            category: ['Column Options'],
            defaultValue: column_values_cfg,
            build: (builder) => {
                builder
                .addTextInput({
                    path: "reorderColumns",
                    name: "Reorder Columns",
                    description: "Reorder the columns in the table.",
                    defaultValue: column_values_cfg.reorderColumns
                })
                .addTextInput({
                    path: "mergeColumns",
                    name: "Merge Columns",
                    description: "Merge the columns in the table.",
                    defaultValue: column_values_cfg.mergeColumns
                })
                .addTextInput({
                    path: "isMeasurement",
                    name: "Measurement Column",
                    description: "Column Data is a measurment of some type (e.g. weight, length, etc)",
                    defaultValue: column_values_cfg.isMeasurement
                })
                .addTextInput({
                    path: "columnsHide",
                    name: "Hide Column",
                    description: "Determine which columnes to remove or hide. Does not work in conjuncture with the Show Column feature (0 Indexed)",
                    defaultValue: column_values_cfg.columnsHide
                })
                .addTextInput({
                    path: "columnsShow",
                    name: "Show Column",
                    description: "Use comma delimited values to determine which rows to show. Does not work if columns are selected to hide. (0 Indexed)",
                    defaultValue: column_values_cfg.columnsShow
                })
                .addNumberInput({
                    path: "columnMinWidth",
                    name: "Min Width of Columns",
                    description: "Set the standard mininum width of all columns",
                    defaultValue: column_values_cfg.columnMinWidth
                })
                .addTextInput({
                    path: "columnsEachWidth",
                    name: "Column Width",
                    description: "Width of each Column (0 Indexed) (e.g: 0:200,1:100,2:300,3-5:250)",
                    defaultValue: column_values_cfg.columnsEachWidth
                })
                .addTextInput({
                    path: "columnsBold",
                    name: "Bold Columns",
                    description: "Columns to Bold (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.columnsBold
                })
                .addTextInput({
                    path: "columnsItalic",
                    name: "Italic Columns",
                    description: "Columns to Italic (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.columnsItalic
                })
                .addBooleanSwitch({
                    path: "showColumnNumbers",
                    name: "Show Column Numbers",
                    description: "toggle to see the column numbers.",
                    defaultValue: column_values_cfg.showColumnNumbers,
                })
                .addTextInput({
                    path: "defaultColumnValues",
                    name: "Default Values from Columns",
                    description: "Default Values from Columns",
                    defaultValue: column_values_cfg.defaultColumnValues
                })
                .addTextInput({
                    path: "defaultHeaderColor",
                    name: "Default Color from ColumnHeader",
                    description: "Default Values from Columns",
                    defaultValue: column_values_cfg.columnHeaderColor
                })
                .addTextInput({
                    path: "editableCells",
                    name: "Editable Cells",
                    description: "Make cells Editable",
                    defaultValue: column_values_cfg.editableCells
                })
                .addTextInput({
                    path: "editableCellsValues",
                    name: "Editable Cell Values",
                    description: "Add values using JSON format for the editable cells. e.g e.g {col_num: {row_number: {'type': 'html_type'}}}. Use -1 in place of undefined. Valid types are : checkbox, date, datetime-local, email, month, number, password, range, tel, text, time, url, week",
                    defaultValue: column_values_cfg.editableCellsValues
                })
                .addTextInput({
                    path: "editableCellsLimit",
                    name: "Editable Cell Limit",
                    description: "Add values using JSON format for the editable cells. e.g {col_num: {row_number: {'limit': ammount}}}. Use -1 in place of undefined.",
                    defaultValue: column_values_cfg.editableCellsLimit
                })
                .addTextInput({
                    path: "dropdownCells",
                    name: "Dropdown Cells",
                    description: "Make cells Dropdown Menus",
                    defaultValue: column_values_cfg.dropdownCells
                })
                .addTextInput({
                    path: "dropdownCellValues",
                    name: "Dropdown Cell Values",
                    description: "Add values using JSON format for the dropdowns. e.g {col_num: {row_number: {'key': 'selction_name', 'value': 'selection_value'}}}. Use -1 in place of undefined.",
                    defaultValue: column_values_cfg.dropdownCellValues
                })
                .addTextInput({
                    path: "cotaStatus",
                    name: "Define columns for cotaStatus",
                    description: "Columns to Cota Status (Badges) (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaStatus
                })
                .addTextInput({
                    path: "cotaStatusColors",
                    name: "Define columns for cotaStatusColors",
                    description: "Use following format: Loadblocks:green-badge,straps:yellow-badge,etc...w)",
                    defaultValue: column_values_cfg.cotaStatusColors
                })
                .addTextInput({
                    path: "cotaSources",
                    name: "Define columns for CotaSources",
                    description: "Columns to Cota Sources (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaSources
                })
                .addTextInput({
                    path: "cotaRoles",
                    name: "Define columns for cotaRoles",
                    description: "Columns to Cota Sources (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaRoles
                })
                .addTextInput({
                    path: "cotaRolesColors",
                    name: "Define columns for cotaRolesColors",
                    description: "Columns to Cota Sources (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaRolesColors
                })
                .addTextInput({
                    path: "cotaRolesIcons",
                    name: "Define columns for cotaRolesIcons",
                    description: "Columns to Cota Sources (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaRolesIcons
                })
                .addTextInput({
                    path: "cotaProfiles",
                    name: "Define columns for CotaProfiles",
                    description: "Columns to Cota Profiles (User or Assets) (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.cotaProfiles
                })
                .addTextInput({
                    path: "defaultClickableColumns",
                    name: "Define columns for clickableColumns",
                    description: "Columns to Cota Profiles (User or Assets) (0 Indexed) (e.g: 0-2,3)",
                    defaultValue: column_values_cfg.clickableColumns
                })
            }
        })
        .addNestedOptions({
            path: "rowOptions",
            category: ['Row Options'],
            defaultValue: row_values_cfg,
            build: (builder) => {
                builder
                    .addBooleanSwitch({
                        path: "mouseClickTriggers",
                        name: "Mouse Click Row Triggers",
                        description: 'Toggle for activation of clicking triggers mouse_up/mouse_down',
                        defaultValue: row_values_cfg.mouseClickTriggers
                    })
                    .addBooleanSwitch({
                        path: "hightlightRow",
                        name: "Hightlight Row",
                        description: 'Toggle for activation of row highlighting',
                        defaultValue: row_values_cfg.hightlightRow
                    })
                    .addTextInput({
                        path: "hightlightRowColor",
                        name: "Color or Highlighting Rows",
                        description: "Use a HTML compatible label-name or RGB Color Code",
                        defaultValue: row_values_cfg.hightlightRowColor
                    })
                    .addBooleanSwitch({
                        path: 'usePointer',
                        name: 'Add Pointer to hover above Rows only',
                        description: "Mouse pointer is present when mouse over of the rolls even if there are not any associated triggers",
                        defaultValue: row_values_cfg.usePointer,
                    })
                    .addBooleanSwitch({
                        path: "disableRowTrigger",
                        name: "Disable Triggers from Row",
                        description: "Toggle this on in order to disable Row Based clicks, so it only works from the Button.",
                        defaultValue: row_values_cfg.disableRowTrigger
                    })
                    .addBooleanSwitch({
                        path: "isTriggerable",
                        name: "Rows or Buttons can result in a trigger",
                        description: "Allows for interaction with the Row (either by click or button) to result in a trigger",
                        defaultValue: row_values_cfg.isTriggerable
                    })
                    .addBooleanSwitch({
                        path: "includeRowNumbers",
                        name: "Include numbers for each Row",
                        description: "Toggle on or off to see the number assigned to each row",
                        defaultValue: true
                    })
            }
        })
        .addNestedOptions({
            path: "colorOptions",
            category: ['Color Options'],
            defaultValue: color_values_cfg,
            build: (builder) => {
                builder
                    .addTextInput({
                        path: "hightlightRowColor",
                        name: "Color or Highlighting Rows",
                        description: "Use a HTML compatible label-name or RGB Color Code",
                        defaultValue: color_values_cfg.hightlightRowColor
                    })
                    .addTextInput({
                        path: "hightlightColumnColor",
                        name: "Color or Highlighting Columns",
                        description: "Use a HTML compatible label-name or RGB Color Code",
                        defaultValue: color_values_cfg.hightlightRowColor
                    })
                    .addTextInput({
                        path: "defaultColumnColor",
                        name: "Default Column Colors",
                        description: "Default Color for all columns",
                        defaultValue: color_values_cfg.defaultColumnColor
                    })
                    .addTextInput({
                        path: "columnsEachColor",
                        name: "Column Colors",
                        description: "Color of Columns (0 Indexed) (e.g: 0:#900,1:#090,2:#009,3-5:#099)",
                        defaultValue: color_values_cfg.columnsEachColor
                    })
                    .addTextInput({
                        path: "defaultColorValues",
                        name: "Default Values from Colors",
                        description: "Default Values from Colors",
                        defaultValue: color_values_cfg.defaultColorValues
                    })
                    .addTextInput({
                        path: "headerColor",
                        name: "Default Headers Colors",
                        description: "Default Color for all Headers",
                        defaultValue: color_values_cfg.headerColor
                    })
            }
        })
        .addBooleanSwitch({
            path: 'toggleRowBorder',
            name: 'Show Row Borders',
            description: "",
            defaultValue: true,
        })
        .addBooleanSwitch({
            path: 'toggleColumnBorder',
            name: 'Show Column Borders',
            description: "",
            defaultValue: false,
        })
        .addBooleanSwitch({
            path: 'seriesAddHide',
            name: 'Show Hide Button',
            description: "",
            defaultValue: false,
        })
        .addNumberInput({
            path: "hideRowColumnWidth",
            name: "Hide Button Column Width",
            description: "Set the standard mininum width of hide button column",
            defaultValue: 50
        })
    }
);
