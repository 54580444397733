export interface ConvertValues {
    currencyColumns?: string;
    phoneNumberColumns?: string;
    percentColumns?: string;
    decimalColumns?: string;
    truncateColumn?: string;
    weightColumns?: string;
    lengthColumns?: string;
    speedColumns?: string;
    tempColumns?: string;
    aliasColumnValues?: string;
}
export const defaultConvertValues: ConvertValues = {
    currencyColumns: "",
    phoneNumberColumns: "",
    percentColumns: "",
    decimalColumns: "",
    truncateColumn: "",
    weightColumns: "",
    lengthColumns: "",
    speedColumns: "",
    tempColumns: "",
    aliasColumnValues: ""
}
