import { CotaOptions } from "types";
import { check_if_flagged, is_number, limit_number } from "./UtilityFunctions";

export function convert_to_currency(value: string) {
    if (!value.includes(".")) {
        value = value + ".00"
    }
    return "$" + value
}
export function convert_to_phone(phone_number: string) {
    return phone_number.substring(0, 3) + "." + phone_number.substring(3, 6) + "." + phone_number.substring(6, phone_number.length);
}

export function convert_to_percent(value: string) {
    return value + "%"
}
export function convert_to_decimal(value: string) {
    if (!value.includes(".")) {
        value = value.slice(0, -2) + "." + value.slice(value.length-2, value.length)
    }
    return value
}
export function convert_to_bold(index: number, override: number, options: CotaOptions) {
    if (override !== 0) {
        return override.toString()
    }
    if (options.columnOptions?.columnsBold !== undefined) {
        if (options.columnOptions?.columnsBold !== "") {
            let values = options.columnOptions?.columnsBold.split(/[, ]+/);
            if (check_if_flagged(index, values, options.columnOptions?.columnsBold)) {
                return "600"
            }
        }
    }
    return "400"
}
export function convert_to_italic(index: number, override: number, options: CotaOptions) {
    if (override !== 0) {
        return override.toString()
    }
    if (options.columnOptions?.columnsItalic !== undefined) {
        if (options.columnOptions?.columnsItalic !== "") {
            let values = options.columnOptions?.columnsItalic.split(/[, ]+/);
            if (check_if_flagged(index, values, options.columnOptions?.columnsItalic)) {
                return "italic"
            }
        }
    }
    return "normal"
}
export function convert_weight(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : (Number(value) * 0.002205)
        if (measurement) {
            if (calc_value > 1000) {
                return (limit_number(calc_value) / 1000)
            }
            return limit_number(calc_value)
        } else {
            if (calc_value > 2000) {
                return (limit_number(calc_value) / 2000)
            }
            return limit_number(calc_value)
        }
    }
    return value
}
export function convert_length(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : (Number(value) * 0.3048)
        if (measurement) {
            if (calc_value > 1000) {
                return (limit_number(calc_value) / 1000)
            }
            return limit_number(calc_value)
        } else {
            if (calc_value > 12) {
                return (limit_number(calc_value) / 12)
            }
            return limit_number(calc_value)
        }
    }
    return value
}

export function convert_speed(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : (Number(value) * 1.609344)
        return limit_number(calc_value)
    }
    return value
}
export function convert_temperature(value: string, measurement: boolean) {
    if (is_number(value)) {
        let calc_value = measurement ? Number(value) : ((Number(value) * 1.8) + 32)
        return limit_number(calc_value)
    }
    return value
}
export function convert_title(value: string) {
    return value
}
export function convert_to_status(index: number, override: number, options: CotaOptions) {
    if (override !== 0) {
        return override.toString()
    }
    if (options.columnOptions?.columnsBold !== undefined) {
        if (options.columnOptions?.columnsBold !== "") {
            let values = options.columnOptions?.columnsBold.split(/[, ]+/);
            if (check_if_flagged(index, values, options.columnOptions?.columnsBold)) {
                return "600"
            }
        }
    }
    return "400"
}
export function convert_to_profiles(index: number, override: number, options: CotaOptions) {
    if (override !== 0) {
        return override.toString()
    }
    if (options.columnOptions?.columnsBold !== undefined) {
        if (options.columnOptions?.columnsBold !== "") {
            let values = options.columnOptions?.columnsBold.split(/[, ]+/);
            if (check_if_flagged(index, values, options.columnOptions?.columnsBold)) {
                return "600"
            }
        }
    }
    return "400"
}
