export interface ColumnValues {
    columnMinWidth: number;
    defaultColumnColor?: string;
    currencyColumns?: string;
    reorderColumns?: string;
    mergeColumns?: string;
    phoneNumberColumns?: string;
    columnsHide?: string;
    columnsShow?: string;
    columnsEachWidth?: string;
    columnsEachColor?: string;
    showColumnNumbers: boolean;
    columnsBold?: string;
    columnsItalic?: string;
    hightlightColumn: boolean;
    isMeasurement: string;
    defaultColumnValues: string;
    editableCells?: string;
    editableCellsValues?: string;
    editableCellsLimit?: string;
    dropdownCells?: string;
    dropdownCellValues?: string;
    columnHeaderColor?: string;
    cotaStatus?: string;
    cotaStatusColors?: string;
    cotaRoles?: string;
    cotaRolesColors?: string;
    cotaRolesIcons?: string;
    cotaSources?: string;
    cotaProfiles?: string;
    clickableColumns?: string;
    clickableLinks?: string;
    cotaMenu?: string;
    cotaMenuOptions?: string;
}
export const defaultColumnValues: ColumnValues = {
    columnMinWidth: 220,
    defaultColumnColor: "#043b64",
    currencyColumns: "",
    reorderColumns: "",
    mergeColumns: "",
    phoneNumberColumns: "",
    columnsHide: "",
    columnsShow: "",
    columnsEachWidth: "",
    columnsEachColor: "",
    showColumnNumbers: false,
    columnsBold: "",
    columnsItalic: "",
    hightlightColumn: false,
    isMeasurement: "",
    defaultColumnValues: "",
    editableCells: "",
    editableCellsValues: "",
    editableCellsLimit: "",
    dropdownCells: "",
    dropdownCellValues: "",
    columnHeaderColor: "",
    cotaStatus: "",
    cotaStatusColors: "",
    cotaRoles: "",
    cotaRolesIcons: "",
    cotaRolesColors: "",
    cotaSources: "",
    cotaProfiles: "",
    clickableColumns: "",
    clickableLinks: "",
    cotaMenu: "",
    cotaMenuOptions: ""
};
