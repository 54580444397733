export function check_map_for_flagged(values: string[], index: number, original_value: string | undefined) {
    if (original_value === undefined) {
        original_value = ""
    }
    let return_value = original_value
    values.map((value_options, _) => {
        let split_results = value_options.split(/\:/);
        let is_flagged = false
        if (split_results[0].includes("-")) {
            is_flagged = check_if_number_ranged(index, split_results[0], is_flagged)
        } else {
            if (split_results[0] === index.toString()) {
                is_flagged = true
            }
        }
        if (is_flagged) {
            return_value = split_results[1]
        }
    })
    return return_value
}
export function check_if_flagged(original_index: number, values: string[], array_values: string) {
    let is_flagged = false
    is_flagged = check_if_number_exists(original_index, values)
    if (array_values.includes("-")) {
        values.map((value, _) => {
            if (value.includes("-")) {
                is_flagged = check_if_number_ranged(original_index, value, is_flagged)
            }
        })
    }
    return is_flagged
}

export function strip_text(title: string, match_text?: string | undefined, replace_text?: string | undefined) {
    match_text = (match_text === undefined) ? '_' : match_text
    replace_text = (replace_text === undefined) ? ' ' : replace_text
    let refactored_title = replace_all(title, match_text, replace_text)
    return capitalize(refactored_title)
}
function replace_all(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escape_reg_exp(find), 'g'), replace);
}
function escape_reg_exp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function capitalize(str: string){
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/////////////////////////////////////////////////////////////////
function check_if_number_exists(index: number, values: string[]) {
    if (values.indexOf(index.toString()) > -1) {
        return true
    }
    return false
}
function check_if_number_ranged(index: number, value: string, current_flag: boolean) {
    let is_flagged = current_flag
    let values = value.split(/[-]/);
    if (index >= Number(values[0]) && index <= Number(values[1])) {
        is_flagged = true
    }
    return is_flagged
}
export function is_number(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }
  
    if (str.trim() === '') {
      return false;
    }
  
    return !Number.isNaN(Number(str));
}

export function check_value_existance(index: number, option_string: string | undefined) {
    if (option_string) {
        if (option_string !== "") {
            let values = option_string.split(/[, ]+/);
            if (check_if_flagged(index, values, option_string)) {
                return true
            }
        }
    }
    return false
}
export function truncate_string(value: string, limit: string) {
    if (is_number(limit)) {
        value = value.substring(value.length-Number(limit), 0);
        return value
    }
    return value + " " + limit
}
export function limit_number(value: number) {
    return Math.round(value * 10) / 10
}
